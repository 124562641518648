
.fa, .fab, .fad, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(../webfonts/fa-solid-900.eot);
  src: url(../webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(../webfonts/fa-solid-900.woff2) format("woff2"), url(../webfonts/fa-solid-900.woff) format("woff"), url(../webfonts/fa-solid-900.ttf) format("truetype"), url(../webfonts/fa-solid-900.svg#fontawesome) format("svg")
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../webfonts/fa-brands-400.eot);
  src: url(../webfonts/fa-brands-400.eot?#iefix) format("embedded-opentype"), url(../webfonts/fa-brands-400.woff2) format("woff2"), url(../webfonts/fa-brands-400.woff) format("woff"), url(../webfonts/fa-brands-400.ttf) format("truetype"), url(../webfonts/fa-brands-400.svg#fontawesome) format("svg")
}

.fa, .far, .fas{
  font-family: "Font Awesome 5 Free"
}

.fab {
  font-family: "Font Awesome 5 Brands"
}

.fa, .fas {
  font-weight: 900
}
.fa-th:before {
  content: "\f00a"
}
.fa-bars:before {
  content: "\f0c9"
}

.fa-angle-double-up:before {
  content: "\f102"
}

.fa-youtube:before {
  content: "\f167"
}
.fa-instagram:before {
  content: "\f16d"
}
.fa-tiktok:before {
  content: "\e07b";
}
.fa-twitter:before {
  content: "\f099"
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "\f291";
}

.fa-telegram:before {
  content: "\f2c6"
}
.fa-facebook:before {
   content: "\f09a"
 }
.fa-whatsapp:before {
   content: "\f232"
 }
.fa-envelope:before {
   content: "\f0e0"
 }
.fa-copy:before {
   content: "\f0c5";
 }
.fa-chevron-down:before {
   content: "\f078";
 }
.fa-circle-xmark:before {
   content: "\f057";
 }

//@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: 'Jost';
  font-stretch: 100%;
  font-style: normal;
  font-display: swap;
  src: url(./jost.woff2) format('woff2');
}
