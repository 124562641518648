@import 'vendors/normalize';
@import 'vendors/responsive';
@import 'vendors/angular-material';
@import 'nice-select';

.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-8 {
  -webkit-line-clamp: 8;
}

.transition {
  -webkit-transition: all 500ms ease-out;
  -moz-transition: all 500ms ease-out;
  -ms-transition: all 500ms ease-out;
  -o-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
}

.w-48{
  width: 12rem;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.link {
  cursor: pointer;
  text-align: center;
  color: #9ca3af;

  &:hover {
    color: #fff;
  }
}

.checkbox-wrapper {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkmark:after {
    display: block;
    background-image: url(~assets/images/icon/check-box.png);
    background-repeat: no-repeat;
    width: 13px;
    height: 10px;
    background-position: center;
  }

  .checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    min-height: 20px;
    width: 20px;
    background-color: #312977;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      display: none;
      background-image: unset;
    }
  }
}

.checkbox-area {
  position: relative;
  min-height: auto;
  cursor: pointer;

  &.required{
    border-bottom: 1px solid red;
  }

  .mdr{
    min-height: auto;
    padding-left: 30px;
    text-align: start;
    line-height: 15px;
    a{
      color: #0dcaf0;
      min-height: auto;
      line-height: inherit;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    min-height: 20px;
    width: 20px;
    border-radius: 5px;
    border: 1px solid #cbcaed;
    transform: translateY(-50%);

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 5px;
      width: 10px;
      height: 10px;
      background-image: url(~assets/images/icon/check.png);
      background-repeat: no-repeat;
    }
  }

  input.checked ~ .checkmark {
    border: 1px solid var(--hover-alt-color);

    &::after {
      display: block;
    }
  }

}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}
