.card {
  position: relative;
  background: white;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  padding: 48px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      margin: 32px 0 0;
      font-size: 30px;
      font-weight: 800;
      letter-spacing: -.022em;
      line-height: 1.25;
    }

    .subtitle {
      display: flex;
      gap: 0 10px;
      align-items: baseline;
      margin-top: 2px;
      width: 100%;

      span {
        font-weight: 500;
      }
    }

    .mat-mdc-form-field {
      width: 100%;
      position: relative;
      //font-size: inherit;
      //font-weight: 400;
      //line-height: 1.125;
      //font-family: Roboto, Helvetica Neue, sans-serif;
      //letter-spacing: normal;

      --mat-form-field-container-text-font: 'Jost', serif;
      --mdc-outlined-text-field-label-text-font: 'Jost', serif;
      --mdc-outlined-text-field-label-text-size: 14px;
      --mdc-outlined-text-field-label-text-weight: 500;
      --mdc-outlined-text-field-label-text-tracking: normal;
      --mdc-outlined-text-field-label-text-color: #000;
      --mat-form-field-container-text-line-height: 42px;
      --mat-form-field-outlined-label-text-populated-size: 14px;
      --mat-mdc-form-field-floating-label-scale: 1;
      --mdc-outlined-text-field-outline-color: #cfd8e3;
      --mdc-outlined-text-field-error-focus-label-text-color: #cfd8e3;
      --mdc-outlined-text-field-disabled-outline-color: #adadad;
      --mdc-outlined-text-field-hover-outline-color: #5850ec;
      --mdc-outlined-text-field-focus-outline-color: #5850ec;
      --mdc-outlined-text-field-error-focus-outline-color: #000;
      --mdc-outlined-text-field-error-outline-color: #f05050;

      $field-container-shape: 24px;

      .mat-mdc-text-field-wrapper {
        &.mdc-text-field--outlined {
          --mdc-outlined-text-field-container-shape: #{$field-container-shape};
          --mdc-outlined-text-field-focus-outline-width: 1px;

          $mt: calc(var(--mat-form-field-container-text-line-height) * 0.5);
          margin-top: $mt;

          .mat-mdc-form-field-flex {
            .mdc-notched-outline .mdc-notched-outline__notch {
              border-top: solid;
              border-width: var(--mdc-outlined-text-field-outline-width);
              border-top-color: var(--mdc-outlined-text-field-outline-color);
            }

            .mdc-floating-label {
              &.mdc-floating-label--float-above {
                transform: translateY(-50px);
                top: 20px;
              }
            }

          }

          &:not(.mdc-text-field--disabled) {
            &:not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch {
              border-top-color: var(--mdc-outlined-text-field-hover-outline-color);
            }

            &.mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__notch {
              border-top-color: var(--mdc-outlined-text-field-focus-outline-color);
            }
          }

          &.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__notch {
            border-top-color: var(--mdc-outlined-text-field-hover-outline-color);
          }
        }

        &.mdc-text-field--invalid {
          .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch {
            border-top-color: var(--mdc-outlined-text-field-error-outline-color);
          }
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        line-height: 20px;

        .mat-mdc-form-field-error-wrapper {
          padding-right: max(16px, #{$field-container-shape});
          padding-left: max(16px, #{$field-container-shape});

          .mat-mdc-form-field-error {
            color: #f05050;
            font-size: 12px;

          }
        }
      }

      .mat-mdc-icon-button {
        padding: 10px;
        --mdc-icon-button-state-layer-size: 42px;;
        --mdc-icon-button-icon-color: #97a6ba;

        .mat-mdc-button-touch-target {

          height: var(--mdc-icon-button-state-layer-size);
          width: var(--mdc-icon-button-state-layer-size);
        }
      }

      textarea {
        line-height: 22px;
      }
    }

    .mat-mdc-checkbox .mdc-form-field {
      .mdc-checkbox {
        --mdc-checkbox-state-layer-size: 1.5rem;
      }

      .mdc-label {
        text-align: left;
        line-height: 1.2;
      }

    }


  }

  @media (max-width: 799px) {
    max-width: 100%;
    width: 100%;
    padding: 16px;
  }

}

.cdk-overlay-container {
  position: fixed;
  z-index: 1010;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &:empty {
    display: none;
  }

  .cdk-overlay-backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;

    &.cdk-overlay-backdrop-showing.cdk-overlay-dark-backdrop {
      opacity: 1;
    }
  }

  .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .mat-mdc-dialog-container .mdc-dialog__surface .cdk-overlay-pane {
      max-width: 100% !important;

    }

    .mat-mdc-snack-bar-container .mdc-snackbar__surface {
      background: #3d329b;
    }
  }

  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
    position: relative;
  }

  .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    max-width: 100% !important;

    .mat-mdc-select-panel {
      --mat-select-panel-background-color: #fff;

      .mat-mdc-option .mat-pseudo-checkbox-minimal {
        display: none;
      }
    }

  }

  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
  }
}

button {
  cursor: pointer;
}

button {
  &.is-default {
    width: 100%;
    margin: 1rem 0;
    //padding: 12px 0;
    border: 0.740175px solid #C7C7C7;
    background: rgba(255, 255, 255, .45);
    border-radius: 16.2838px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03), 0 14px 14px 0 rgba(0, 0, 0, 0.03), 0 31px 19px 0 rgba(0, 0, 0, 0.02), 0 55px 22px 0 rgba(0, 0, 0, 0.00), 0 86px 24px 0 rgba(0, 0, 0, 0.00);
    position: relative;
    transition: all ease .3s;
    @media (max-width: 799px) {
      margin: 16px 0;
    }

    &:not(.is-primary):not(.is-delete):hover, &:not(.is-primary):not(.is-delete):focus {
      background: linear-gradient(180deg, #0091e0 0%, #005483 100%);

      span {
        color: #fff;

        //&:after {
        //  opacity: 1;
        //  background: white;
        //}
      }
    }

    span {
      position: relative;
      width: 100%;
      color: #000;
      font-size: 20px;
      //font-family: Poppins, serif;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      text-align: center;
      padding: 12px 70px;
      transition: all 0.3s;
      display: block;

      //&:after {
      //  content: '';
      //  width: 100%;
      //  height: 4px;
      //  position: absolute;
      //  bottom: -22.5px;
      //  left: 50%;
      //  transform: translateX(-50%);
      //  transition: all ease .3s;
      //  opacity: 0;
      //}
    }

    &.is-smaller {
      margin: 0;
      width: auto;
      padding: 0;

      span {
        padding: 0.5rem 2rem;
        font-size: 1em;
      }
    }
  }

  &.is-primary {
    background: #574feb;

    span {
      color: #fff;
    }
  }

  &.is-delete {
    background: #c61e1f;

    span {
      color: #fff;
    }
  }

  &.is-process {
    position: relative;
    background: linear-gradient(180deg, #0091e0 0%, #005483 100%);

    span {
      opacity: 0;
    }

    &:after {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      border-radius: 50%;
      display: inline-block;
      border-top: 3px solid #FFF;
      border-right: 3px solid transparent;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
