:root {
  --body-font: "Jost", sans-serif;
  --body-color: #070044;
  --bg-color: #7924c7;
  --para-color: #f7f4ff;
  --para-alt-color: #b2b4e1;
  --hover-color: #00ff66;
  --hover-alt-color: #5ac4ff;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  min-height: 100%;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: var(--body-font);
  background-color: var(--body-color);
  font-size: 18px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
}

app-root {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
}

main {
  display: block;
}


layout {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  min-width: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

section,
footer {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h1,
h1 > a,
h2,
h2 > a,
h3,
h3 > a,
h4,
h4 > a,
h5,
h5 > a,
h6,
h6 > a {
  font-family: var(--body-font);
  color: var(--bs-white);
  font-weight: 600;
  text-transform: initial;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover,
h5 > a:hover,
h6 > a:hover {
  color: var(--body-color);
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: none;
  color: var(--para-color);
}

img {
  max-width: 100%;
  height: auto;
}

.max-un {
  max-width: unset;
}

.d-rtl {
  direction: rtl;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #b0baf1;
}

button {
  border: none;
  cursor: pointer;
}

input,
textarea {
  padding: 10px 20px;
  color: #1e266d;
  width: 100%;
  font-family: var(--body-font);
  height: 50px;
  background: #f5faff;
  border: 1px solid #eef1ff;
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  width: initial;
  height: initial;
}

select {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  outline: none;
}

textarea {
  min-height: 150px;
  resize: none;
}

span {
  display: inline-block;
}

span.error {
  line-height: 14px;
  font-size: 14px;
  min-height: 14px;
  padding-left: 20px;
  color: #f05050;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.table thead th,
.table td,
.table th {
  border: none;
}

.d-grid {
  display: grid !important;
}

.b-none {
  border: none !important;
}

.ovf-unset {
  overflow-x: unset !important;
}

.collapsing {
  transition: 0.3s;
}

iframe {
  border: 0;
  width: 100%;
}

.shadow-none {
  -webkit-box-shadow: none;
  box-shadow: none;
}

h1,
h1 > a {
  font-size: 76px;
  line-height: 98.8px;
  min-height: 98.8px;
  margin-top: -18px;
}

h2,
h2 > a {
  font-size: 57px;
  line-height: 74.1px;
  min-height: 74.1px;
  margin-top: -12px;
}

h3,
h3 > a {
  font-size: 43px;
  line-height: 55.9px;
  min-height: 55.9px;
  margin-top: -8px;
}

h4,
h4 > a {
  font-size: 32px;
  line-height: 41.6px;
  min-height: 41.6px;
  margin-top: -7px;
}

h5,
h5 > a {
  font-size: 24px;
  line-height: 31.2px;
  min-height: 31.2px;
  margin-top: -5px;
}

h6,
h6 > a {
  font-size: 18px;
  line-height: 23.4px;
  min-height: 23.4px;
  margin-top: -3px;
}

table thead tr th {
  font-size: 18px;
  line-height: 30px;
  min-height: 30px;
}

a {
  display: inline-block;
  color: var(--para-color);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  min-height: 30px;
}

p,
span,
li,
label {
  margin: 0;
  font-family: var(--body-font);
  font-size: 18px;
  font-weight: 400;
  color: var(--para-color);
  line-height: 30px;
  min-height: 30px;
}

.section-text {
  margin-bottom: 34px;
}

.section-header {
  margin-bottom: 55px;
}

.sub-title {
  color: var(--hover-color);
  margin-bottom: 30px;
}

.title {
  font-weight: 700;
  margin-bottom: 16px;
}

.xxlr {
  font-size: 26px;
  line-height: 36px;
  margin-top: -7px;
}

.xlr {
  font-size: 24px;
  line-height: 36px;
  margin-top: -7px;
}

.mdr {
  font-size: 16px;
  line-height: 22px;
  min-height: 22px;
}

.z-1 {
  z-index: 1;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.pt-120 {
  padding-top: 120px;
}

@media (max-width: 991px) {
  .pt-120 {
    padding-top: 60px;
  }
  .mb-60 {
    margin-bottom: 40px;
  }
  .mt-60 {
    margin-top: 40px;
  }
  .mb-80 {
    margin-bottom: 60px;
  }
  .mt-80 {
    margin-top: 60px;
  }
  .header-section {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
    background-color: #2d0572;
  }
}

@media (max-width: 575px) {
  .pt-120 {
    padding-top: 50px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

@media (max-width: 575px) {
  .pb-120 {
    padding-bottom: 50px;
  }
}

*::-webkit-scrollbar-track {
  background: #00003e;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-button,
*::-webkit-scrollbar-thumb {
  background-color: #5732c6;
}

.scrollToTop {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 45px;
  height: 45px;
  background-color: var(--hover-color);
  border-radius: 5px;
  color: #fff;
  line-height: 45px;
  font-size: 20px;
  text-align: center;
  z-index: 9;
  cursor: pointer;
  transition: all 1s;
  transform: translateY(100%);
}

.scrollToTop.active {
  bottom: 30px;
  transform: translateY(0%);
  color: var(--body-color);
}

.scrollToTop:hover {
  color: var(--body-color);
}

.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
  overflow: visible;
  background: url('~assets/images/preloader.gif') no-repeat center center;
  background-size: 20%;
  background-color: #e5eff1;
}

.icon-svg {
  position: relative;

  img {
    opacity: 1;
    position: absolute;
  }

  svg {
    position: absolute;
    stroke-dashoffset: 1500;
    stroke-dasharray: 1500;
    transform: scale(1);
    transition: transform 0.3s, fill 0.5s;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    svg {
      visibility: visible;
      opacity: 1;
      stroke-dashoffset: 0;
      fill: transparent;
      transition: transform 0.3s, fill 0.5s, stroke-dashoffset 6s;
    }

    img {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.accordion-button:focus {
  box-shadow: none;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.single-input {
  position: relative;
}

.w-full{
  width: 100%;
}
/* cmn btn */
.cmn-btn {
  cursor: pointer;
  padding: 10px 30px;
  font-weight: 600;
  text-align: center;
  background: var(--bg-color);
  color: var(--bs-white);
  border: 1px solid var(--bg-color);
  transition: 0.3s;
  border-radius: 50px;

  &:hover {
    background: transparent;
    border: 1px solid #7867de;
  }

  &.is-process {
    position: relative;
    background: linear-gradient(180deg, #0091e0 0%, #005483 100%);

    span {
      opacity: 0;
    }

    &:after {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      border-radius: 50%;
      display: inline-block;
      border-top: 3px solid #FFF;
      border-right: 3px solid transparent;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }

  &.second {
    background: transparent;
    border: 1px solid #7867de;

    &:hover {
      background: var(--bg-color);
      border: 1px solid var(--bg-color);
    }
  }
}

.loader:after {
  content: '';
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

// alt-btn
.alt-btn {
  padding: 15px 30px;
  border: 1px solid #5e549a;
  border-radius: 10px;
  transition: 0.3s;

  &:hover {
    background: var(--bg-color);
    border-color: var(--bg-color);
  }
}

.modal-dialog {
  --bs-modal-width: 500px;
}

// Animation
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(-5px, -5px);
  }
  66% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(5px, -5px);
  }
  66% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
