@media (max-width: 1199px) {
  h1, h1>a {
    font-size: 57px;
    line-height: 68.4px;
    min-height: 68.4px;
  }
  h2, h2>a {
    font-size: 43px;
    line-height: 51.6px;
    min-height: 51.6px;
  }
}
@media (max-width: 991px) {
  h1, h1>a {
    font-size: 57px;
    line-height: 68.4px;
    min-height: 68.4px;
    margin-top: -12px;
  }

  h2, h2>a {
    font-size: 43px;
    line-height: 51.6px;
    min-height: 51.6px;
    margin-top: -8px;
  }

  h3, h3>a {
    font-size: 32px;
    line-height: 38.4px;
    min-height: 38.4px;
    margin-top: -7px;
  }

  h4, h4>a {
    font-size: 24px;
    line-height: 28px;
    min-height: 28px;
    margin-top: -5px;
  }

  h5, h5>a {
    font-size: 18px;
    line-height: 22px;
    min-height: 22px;
    margin-top: -3px;
  }

  h6, h6>a, table thead tr th, a, p, span, li {
    font-size: 16px;
    line-height: 22px;
    min-height: 22px;
  }
  .section-header {
    margin-top: -8px;
    margin-bottom: 40px;
  }
  .title {
    margin-bottom: 20px;
  }

  .pb-120 {
    padding-bottom: 60px;
  }
}
@media (max-width: 575px) {
  h1, h1 > a {
    font-size: 40px;
    line-height: 46px;
    min-height: 46px;
    margin-top: -7px;
  }
  h2, h2 > a {
    font-size: 30px;
    line-height: 36px;
    min-height: 36px;
    margin-top: -6px;
  }
  h3, h3 > a {
    font-size: 24px;
    line-height: 28px;
    min-height: 28px;
    margin-top: -5px;
  }
  h4, h4 > a {
    font-size: 18px;
    line-height: 22px;
    min-height: 22px;
    margin-top: -3px;
  }
  a, p, span, li, .cmn-btn, thead tr th, tbody tr th, tbody tr td, input, label, textarea, h6, h6 > a {
    font-size: 15px;
    line-height: 19px;
    min-height: 19px;
  }
  .section-header {
    margin-top: -5px;
    margin-bottom: 30px;
  }
  .title {
    margin-bottom: 15px;
  }
  .pb-120 {
    padding-bottom: 50px;
  }
}
